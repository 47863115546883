import { handleFetching } from 'symbiote-fetching';

import { getClientProductPositions } from 'features/wallet/effects';
import { getResponse } from 'features/common/response';

import { actions as actionsNotifications } from '../notification/symbiotes';

import { actions } from './symbiotes';
import { clearPosition } from './models';
import { mapAccount, mapClient, mapSymbols, mapSymbolsMyAssets } from './mappers';
import { accountApi } from './api';

export const getClient = () =>
  handleFetching(actions.fetchClient, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getClient);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let client = mapClient(response);
          await dispatch(actions.setClient(client));
          await dispatch(actionsNotifications.setEventPush({ eventSended: false }));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getAccount = () =>
  handleFetching(actions.fetchAccount, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getAccount);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          const account = mapAccount(response);
          await dispatch(setDefaultPositions);
          await dispatch(actions.setAccount(account));
          await dispatch(actions.setCurrentAccount(account.accounts[0]));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicAccount = () =>
  handleFetching(actions.fetchAccount, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getPublicAccount);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          const account = mapAccount(response);
          await dispatch(setDefaultPositions);
          await dispatch(actions.setAccount(account));
          await dispatch(actions.setCurrentAccount(account.accounts[0]));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const updateAccount = () =>
  handleFetching(actions.fetchUpdateAccount, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getAccount);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          const account = mapAccount(response);
          Promise.allSettled([
            dispatch(setDefaultPositions),
            actions.setAccount(account),
            actions.setCurrentAccount(account.accounts[0]),
            dispatch(getClientSymbolsMyAssets),
            dispatch(getClientProductPositions),
          ]);
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const checkPosition = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.checkPosition, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          if (query?.MaxPrice) {
            dispatch(actions.setPosition(response));
          } else {
            dispatch(actions.setPosition(response));
            dispatch(actions.setPositionCurrent(response));
          }
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const resetPosition = () =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        dispatch(actions.setPosition(clearPosition));
        dispatch(actions.setPositionCurrent(clearPosition));
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const setDefaultPositions = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getPublicSymbols, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let symbols = mapSymbols(response.rows);
          dispatch(actions.setSymbols(symbols));
          dispatch(actions.setDefaultPositions(symbols));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getClientPnL = () =>
  handleFetching(actions.fetchClientPnL, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getClientPnL);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          await dispatch(actions.setClientPnL(response.source_data));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicClientPnL = () =>
  handleFetching(actions.fetchClientPnL, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getPublicClientPnL);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          await dispatch(actions.setClientPnL(response.source_data));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getClientSymbolsMyAssets = () =>
  handleFetching(actions.fetchClientSymbolsMyAssets, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getClientSymbolsMyAssets);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let positions = mapSymbolsMyAssets(response.source_data);
          await dispatch(actions.setSymbolsMyAssets(positions));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicSymbolsMyAssets = () =>
  handleFetching(actions.fetchClientSymbolsMyAssets, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(accountApi.getPublicSymbolsMyAssets);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let positions = mapSymbolsMyAssets(response.source_data);
          await dispatch(actions.setSymbolsMyAssets(positions));
        }
        return {
          ok,
          error,
          data,
        };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });
