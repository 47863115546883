import React from 'react';
import Typography from '@mui/material/Typography';

import { Message } from './message';

const ErrorDialog = ({ show, handleClose, message, dialogTitle }) => (
  <Message dialogTitle={dialogTitle} open={show} setOpen={() => handleClose(!show)}>
    {Array.isArray(message) ? (
      message.map((error, index) => (
        <Typography color="error" key={index} variant="subtitle1">
          {error.message}
        </Typography>
      ))
    ) : typeof message == 'object' ? (
      message.hasOwnProperty('data') && typeof message.data !== 'object' ? (
        <Typography color="error" variant="subtitle1">
          {message.data}
        </Typography>
      ) : message.hasOwnProperty('statusText') ? (
        <Typography color="error" variant="subtitle1">
          {message.statusText}
        </Typography>
      ) : (
        <Typography color="error" variant="subtitle1">
          {JSON.stringify(message)}
        </Typography>
      )
    ) : (
      <Typography color="error" variant="subtitle1">
        {message}
      </Typography>
    )}
  </Message>
);

export { ErrorDialog };
