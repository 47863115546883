import { handleFetching } from 'symbiote-fetching';

import { getResponse } from 'features/common/response';

import { actions } from './symbiotes';
import { AddressTypes } from './models';
import { mapBankDetailWalletsData, mapVasp, mapWalletsData, mapWithdrawalAddressData, mapWithdrawalAddressSymbols } from './mappers';
import { addressesApi } from './api';

export const getWithdrawalAddress = query =>
  handleFetching(actions.fetchWithdrawalAddress, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressesApi.getWithdrawalAddress, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let newData = mapWithdrawalAddressData(response.source_data);
          await dispatch(actions.setWithdrawalAddress(newData));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getWallets = query =>
  handleFetching(actions.fetchWallets, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressesApi.getWallets, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let newData = mapWalletsData(response.source_data);
          await dispatch(actions.setWallets(newData));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicWallets = query =>
  handleFetching(actions.fetchWallets, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressesApi.getPublicWallets, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let newData = mapWalletsData(response.source_data);
          await dispatch(actions.setWallets(newData));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getBankDetailWallets = query =>
  handleFetching(actions.fetchWallets, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressesApi.getBankDetailWallets, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let newData = mapBankDetailWalletsData(response.source_data);
          await dispatch(actions.setBankDetailWallets(newData));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicBankDetailWallets = query =>
  handleFetching(actions.fetchWallets, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressesApi.getPublicBankDetailWallets, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let newData = mapBankDetailWalletsData(response.source_data);
          await dispatch(actions.setBankDetailWallets(newData));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const createWallet = query =>
  handleFetching(actions.fetchCreateWallet, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressesApi.createWallet, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          await dispatch(getWithdrawalAddress);
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const createBankDetailWallet = query =>
  handleFetching(actions.fetchCreateWallet, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressesApi.createBankDetailItem, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          await dispatch(getWithdrawalAddress);
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const updateWallet = ({ id, addressType }, query) =>
  handleFetching(actions.fetchUpdateWallet, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressType === AddressTypes.CRYPTO ? addressesApi.updateWallet : addressesApi.updateBankDetailItem, id, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          await dispatch(getWithdrawalAddress);
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const deleteWallet = (id, query) =>
  handleFetching(actions.fetchDeleteWallet, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressesApi.deleteWallet, id, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          await dispatch(getWithdrawalAddress);
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const deleteBankDetailWallet = (id, query) =>
  handleFetching(actions.fetchDeleteWallet, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressesApi.deleteBankDetailItem, id, query);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          await dispatch(getWithdrawalAddress);
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getWithdrawalAddressSymbols = () =>
  handleFetching(actions.fetchtWithdrawalAddressSymbols, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressesApi.getWithdrawalAddressSymbols);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let newData = mapWithdrawalAddressSymbols(response.source_data);
          await dispatch(actions.setWithdrawalAddressSymbols(newData));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicWithdrawalAddressSymbols = () =>
  handleFetching(actions.fetchtWithdrawalAddressSymbols, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressesApi.getPublicWithdrawalAddressSymbols);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          let newData = mapWithdrawalAddressSymbols(response.source_data);
          await dispatch(actions.setWithdrawalAddressSymbols(newData));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getVasps = () =>
  handleFetching(actions.fetchtVasps, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressesApi.getVasps);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          const newData = mapVasp(response.source_data);
          await dispatch(actions.setVasps(newData));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });

export const getPublicVasps = () =>
  handleFetching(actions.fetchtVasps, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(addressesApi.getPublicVasps);
        const { ok, error, data } = getResponse(response);
        if (ok) {
          const newData = mapVasp(response.source_data);
          await dispatch(actions.setVasps(newData));
        }
        return { ok, error, data };
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });
