import { connect } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

import { useWebsocket } from 'hooks/useWebsocket';
import { useNotificationService } from 'hooks/useNotificationService';
import { useEventPushService } from 'hooks/useEventPushService';
import { useAlertNotificationService } from 'hooks/useAlertNotificationService';
import { getBankDetailWallets, getWallets, getWithdrawalAddress } from 'features/withdrawal-addresses/effects';
import { filterTransactionsSelector } from 'features/transactions/selectors';
import { updateGetTransactions, updatePublicGetTransactions } from 'features/transactions/effects';
import { actions } from 'features/notification/symbiotes';
import { eventPushSelector } from 'features/notification/selectors';
import { PriceUpdateStatuses } from 'features/notification/models';
import { addAlertNotification, addEventPush, addNotification } from 'features/notification/effects';
import { getKYCStatus } from 'features/kyc/status';
import { KYCResults } from 'features/kyc/models';
import { getNavigationSelector } from 'features/common/selectors';
import { NavigationPositions } from 'features/common/models';
import { checkAuthenticated } from 'features/auth/effects/check-authenticated';
import { kycReportSelector } from 'features/account/selectors';
import { getClient, getClientPnL, updateAccount } from 'features/account/effects';

import { authenticatedSelector } from '../features/auth/selectors';

const mapStateToProps = state => ({
  authenticated: authenticatedSelector(state),
  eventPushStore: eventPushSelector(state),
  navigationPosition: getNavigationSelector(state),
  KYCReport: kycReportSelector(state),
  filter: filterTransactionsSelector(state),
});

const mapDispatchToProps = dispatch => ({
  updateNotifications: query => dispatch(addNotification, query),
  updateAlertNotifications: query => dispatch(addAlertNotification, query),
  checkAuthenticated: () => dispatch(checkAuthenticated),
  addEventPush: query => dispatch(addEventPush, query),
  getClient: () => dispatch(getClient),
  getClientPNL: () => dispatch(getClientPnL),
  updateAccount: () => dispatch(updateAccount),
  updateGetTransactions: query => dispatch(updateGetTransactions, query),
  updatePublicGetTransactions: () => dispatch(updatePublicGetTransactions),
  getWallets: query => dispatch(getWallets, query),
  getBankDetailWallets: query => dispatch(getBankDetailWallets, query),
  getWithdrawalAddress: () => dispatch(getWithdrawalAddress),
  updateOnFiatEuroChangedEvent: () => dispatch(actions.updateOnFiatEuroChangedEvent()),
  updateOnCryptoRatesChangedEvent: data => dispatch(actions.updateOnCryptoRatesChangedEvent(data)),
  updateOnGoldQuoteChangedEvent: () => dispatch(actions.updateOnGoldQuoteChangedEvent()),
});

const SocketServiceContainer = ({
  children,
  authenticated,
  updateNotifications,
  updateAlertNotifications,
  addEventPush,
  eventPushStore,
  getClient,
  getClientPNL,
  updateAccount,
  checkAuthenticated,
  navigationPosition,
  updateGetTransactions,
  updatePublicGetTransactions,
  KYCReport,
  getWithdrawalAddress,
  filter,
  updateOnFiatEuroChangedEvent,
  updateOnCryptoRatesChangedEvent,
  updateOnGoldQuoteChangedEvent,
}) => {
  const [ message, setMessage ] = useState({});
  const [ alert, setAlert ] = useState({});
  const [ eventPush, setEventPush ] = useState({ eventSended: false });

  const KYCStatus = getKYCStatus(KYCReport);
  let { parsedMessage, closeConnection, createConnection } = useWebsocket();

  useEffect(() => {
    const { data, action } = parsedMessage;
    if (![ PriceUpdateStatuses.CRYPTO_RATES, PriceUpdateStatuses.FIAT_EURO, PriceUpdateStatuses.GOLD_QUOTE ].includes(action)) {
      if (data && data.Popup === true) {
        setMessage({
          id: data.id,
          status: data.Status,
          title: data.Title,
          message: data.Message,
          type: data.Type,
          createdAt: data.createdAt,
        });
      }
    }
    if ([ PriceUpdateStatuses.CRYPTO_RATES, PriceUpdateStatuses.FIAT_EURO, PriceUpdateStatuses.GOLD_QUOTE ].includes(action)) {
      setAlert({ action: action, data: data });
    } else {
      setAlert({});
    }
    if (action) setEventPush({ eventSended: true });
  }, [ parsedMessage ]);

  useNotificationService({ message, updateNotifications });
  useAlertNotificationService({ alert, updateAlertNotifications });
  useEventPushService({ eventPush, addEventPush });

  useEffect(() => {
    const { data, action } = parsedMessage;

    if (Object.values(PriceUpdateStatuses).includes(action)) {
      if (action === PriceUpdateStatuses.CRYPTO_RATES) {
        updateOnCryptoRatesChangedEvent({ tickers: data });
      } else if (action === PriceUpdateStatuses.FIAT_EURO) {
        updateOnFiatEuroChangedEvent();
      } else if (action === PriceUpdateStatuses.GOLD_QUOTE) {
        updateOnGoldQuoteChangedEvent();
      }
    } else {
      if (authenticated) {
        checkAuthenticated();
      }
      if (authenticated && action) {
        if (KYCStatus === KYCResults.PASSED) {
          updateAccount();
          getClientPNL();
          getClient();
        }
        if (navigationPosition === NavigationPositions.TRANSACTIONS || navigationPosition === NavigationPositions.DASHBOARD) {
          if (KYCStatus === KYCResults.PASSED) {
            updateGetTransactions(filter);
          } else {
            updatePublicGetTransactions();
          }
        }
        if (navigationPosition === NavigationPositions.ADDRESSES) {
          const fetchWithdrawalWallets = async () => {
            await getWithdrawalAddress();
          };
          if (KYCStatus === KYCResults.PASSED) {
            fetchWithdrawalWallets();
          }
        }
      }
    }
  }, [
    updateAccount,
    getClient,
    getClientPNL,
    parsedMessage,
    navigationPosition,
    getWithdrawalAddress,
    KYCStatus,
    updateGetTransactions,
    updatePublicGetTransactions,
    authenticated,
    checkAuthenticated,
    filter,
    updateOnFiatEuroChangedEvent,
    updateOnCryptoRatesChangedEvent,
    updateOnGoldQuoteChangedEvent,
  ]);

  useEffect(() => {
    if (authenticated) {
      createConnection();
    } else if (!authenticated) {
      closeConnection();
    }

    return () => {
      closeConnection();
    };
  }, [ authenticated, createConnection, closeConnection ]);

  return <Fragment>{children}</Fragment>;
};

export const SocketService = connect(mapStateToProps, mapDispatchToProps)(SocketServiceContainer);
