import { pageview, set } from 'react-ga';
import { useEffect } from 'react';

export const trackPage = (page) => {
  set({
    page,
  });
  pageview(page);
};

export const useGoogleAnalyticsHistoryListen = (location) => {
  useEffect(() => {
    if (process.env.REACT_APP_PLATFORM === 'production' || process.env.REACT_APP_PLATFORM === 'prod') {
      if (process.env.REACT_APP_GOOGLE_ANALYST !== '') {
        trackPage(location.pathname);
        const unlistener = trackPage(location.pathname);

        return () => unlistener();
      }
    }
  }, [ location ]);
};
