import { getDateFormat } from 'lib/date';
import { OfferingsType } from 'features/offering/models';
import { KYCClientTypes } from 'features/kyc/models';
import { AssetSymbols, PositionTypes } from 'features/common/models';

import { AccountTypes, symbolsModel } from './models';

const getNameIndvidual = (item) => {
  if (item.Individual?.FirstName && item.Individual.FirstName !== item.Email) {
    return `${item.Individual.FirstName} ${item.Individual.SecondName} ${item.Individual.LastName}`.replace(/ +/g, ' ').trim();
  } else {
    return item.Email;
  }
};

const getNameKycReport = (item) => {
  if (item.fixedInfo?.firstName && item.fixedInfo.firstName !== item.email) {
    return `${item.fixedInfo.firstName} ${item.fixedInfo.middleName} ${item.fixedInfo.lastName}`;
  } else {
    return item.email;
  }
};

export const mapAssets = items =>
  items.map((item) => {
    let asset = {
      name: item.Name,
      ticker: item.Ticker,
      balance: item.Balance,
      availableQty: item.Available,
      blockedQty: item.Blocked,
      estimatedQty: item.Estimated,
      type: item.Type,
      logo: item.ui?.icon,
      color: item.ui?.pie_color ? item.ui?.pie_color : (symbolsModel[item.Ticker]?.color ?? symbolsModel[item.Type]?.color),
    };
    return asset;
  });

export const mapPositions = (items) => {
  let positions = [];
  items.forEach((item) => {
    let newEl = {
      id: item.Symbol.id,
      name: item.Symbol.Ticker,
      nameSymbol: item.Symbol.Name,
      qty: item.Quantity || 0,
      availableQty: item.AvailQty || 0,
      type: item.Symbol.Type,
      accountId: item.AccountId,
      balanceEUR: item.BalanceEUR,
      blockedQty: item.BlockedQty,
      pendingQty: item.PendingQty,
      plannedBuyQty: item.PlannedBuyQty,
      plannedSellQty: item.PlannedSellQty,
      isTransactions: true,
    };
    if (
      (item.Symbol?.Type === PositionTypes.DCR && item.Symbol?.DirectSaleOffer) ||
      (item.Symbol?.Type === PositionTypes.DCR && item.Symbol?.TokenOffering) ||
      (item.Symbol?.Type === PositionTypes.DGR && item.Symbol?.DirectSaleOffer)
    ) {
      newEl = {
        ...newEl,
        typeOfferingDS: item.Symbol?.DirectSaleOffer ? OfferingsType.DIRECTSALES : OfferingsType.OFFERINGS,
        idOfferingDS: item.Symbol?.DirectSaleOffer?.id ?? item.Symbol.TokenOffering.id,
        logo: item.Symbol?.DirectSaleOffer?.Client?.Logo ?? item.Symbol?.TokenOffering?.Client?.Logo ?? '',
        logoDgr: item.Symbol?.DirectSaleOffer?.LogoDgr || '',
      };
    }
    positions.push(newEl);
  });
  return positions;
};

export const mapKYCReportBeneficiaries = (item) => {
  if (item) {
    let beneficiaries = item.Detail?.info?.companyInfo?.beneficiaries;
    return beneficiaries?.map(beneficiar => ({
      name: getNameKycReport(beneficiar),
      KYCStatus: beneficiar.review?.reviewStatus,
      originalType: beneficiar.originalType || null,
      type: beneficiar.type,
      expireAt: getDateFormat(beneficiar.expireAt),
    }));
  }
  return null;
};

const extractBCAddress = (item, type) => {
  if (item) {
    let address = item.BcAddresses.filter(el => el.Type === type);
    if (address?.length) {
      return address.pop().Address;
    }
    return '';
  }
  return '';
};

export const mapClient = (item) => {
  if (item) {
    if (item.Type === KYCClientTypes.INDIVIDUAL) {
      return {
        id: item.id,
        referralLink: item.ReferralLink,
        createdAt: new Date(item.createdAt).getTime(),
        type: item.Type,
        name: getNameIndvidual(item),
        firstName: item.Individual.FirstName,
        secondName: item.Individual.SecondName,
        lastName: item.Individual.LastName,
        dateOfBirth: getDateFormat(item.Individual.DateOfBirth),
        country: item.Individual.Country,
        citizenship: item.Individual.CitizenshipCountry,
        countryOfResidence: item.Individual.CountryOfResidence,
        contractNumber: item.ContractNo,
        contractDate: getDateFormat(item.ContractDate),
        town: item.Individual.Town,
        registrationAddress: item.Individual.RegistrationAddress,
        postalCode: item.Individual.PostalCode,
        email: item.Email,
        phone: item.Phone,
        IDType: item.Individual.IDType,
        IDNumber: item.Individual.IDNumber,
        IDIssueDate: getDateFormat(item.Individual.IDIssueDate),
        IDIssuedBy: item.Individual.IDIssuedBy,
        IDExpireDate: getDateFormat(item.Individual.IDExpireDate),
        checks: item.Individual
          ? item.Individual.Checks.map(check => ({
            status: check.Status,
            type: check.Type,
            statusDate: getDateFormat(check.StatusDate),
          }))
          : [],
        acceptedTermsCount: item.Terms?.length ?? 0,
        DcrClientAddress: item.DcrClientAddress || '',
        BCClientAddressDCR: item.BcAddresses && extractBCAddress(item, 'DCR'),
        BCClientAddressDGR: item.BcAddresses && extractBCAddress(item, 'DGR'),
      };
    }
    if (item.Type === KYCClientTypes.LEGAL_ENTITY) {
      return {
        id: item.id,
        referralLink: item.ReferralLink,
        createdAt: new Date(item.createdAt).getTime(),
        type: item.Type,
        name: (item.Organization?.Name && item.Organization.Name !== item.Email) || item.Email,
        companyName: item.Organization?.Name,
        organizationForm: item.Organization?.LegalForm,
        IDNumber: item.Organization?.IDNumber,
        contractNumber: item.ContractNo,
        contractDate: getDateFormat(item.ContractDate),
        country: item.Organization?.Country,
        town: item.Organization?.Town,
        legalAddress: item.Organization?.LegalAddress,
        postalCode: item.Organization?.PostalCode,
        email: item.Email,
        phone: item.Phone,
        kycReportResult: item.KycReport?.Result,
        kycReportExpireAt: item.KycReport && getDateFormat(item.KycReport.ExpireAt),
        beneficiaries: mapKYCReportBeneficiaries(item.KycReport),
        checks: item.Organization
          ? item.Organization.Checks.map(check => ({
            status: check.Status,
            type: check.Type,
            statusDate: getDateFormat(check.StatusDate),
          }))
          : [],
        acceptedTermsCount: item.Terms?.length ?? 0,
        DcrClientAddress: item.DcrClientAddress || '',
        BCClientAddressDCR: item.BcAddresses && extractBCAddress(item, 'DCR'),
        BCClientAddressDGR: item.BcAddresses && extractBCAddress(item, 'DGR'),
      };
    }

    return {
      id: item.id,
      referralLink: item.ReferralLink,
      type: item.Type,
      name: item.Email,
      contractNumber: item.ContractNo,
      contractDate: getDateFormat(item.ContractDate),
      email: item.Email,
      phone: item.Phone,
      checks: [],
      acceptedTermsCount: item.Terms?.length ?? 0,
      DcrClientAddress: item.DcrClientAddress || '',
      BCClientAddressDCR: item.BcAddresses?.filter(el => el.Type === 'DCR')?.pop()?.Address || '',
      BCClientAddressDGR: item.BcAddresses?.filter(el => el.Type === 'DGR')?.pop()?.Address || '',
    };
  }
  return null;
};

export const mapAccount = (items) => {
  if (items?.hasOwnProperty('0')) {
    const VNXAccounts = Object.values(items).filter(account => account.Type === AccountTypes.VNX);
    const VNXBalance = VNXAccounts?.[0].Balance || 0;
    const VNXAccountID = VNXAccounts?.[0].id || null;
    const VNXSymbolID = VNXAccounts?.[0].Symbol.id || null;

    const VNXQuantity = VNXAccounts?.[0]?.Positions?.[0]?.Quantity || 0;

    let vnxPosition = Object.values(VNXAccounts[0].Positions).filter(position => position?.Symbol?.Type === PositionTypes.VNX) || [];
    let VNXPositions = (VNXAccounts && mapPositions(vnxPosition)) || [];
    const VNXType = VNXAccounts && VNXAccounts[0].Symbol.Type;
    if (!VNXPositions.length) {
      VNXPositions = [
        {
          id: VNXSymbolID,
          name: AssetSymbols.VNXLU,
          qty: 0,
          availableQty: 0,
          type: PositionTypes.VNX,
          accountId: '',
          balanceEUR: 0,
          blockedQty: 0,
          pendingQty: 0,
          isTransactions: false,
        },
      ];
    }

    const StructuredProductsAccount = Object.values(items).find(account => account.Type === AccountTypes.STRUCTURED_PRODUCTS);
    const StructuredProductsBalance = StructuredProductsAccount?.Balance || 0;

    const tradingAccounts = Object.values(items).filter(account => account.Type === AccountTypes.TRADING);
    const tradingAccountBase = tradingAccounts[0];
    const tradingBalance = tradingAccountBase?.Balance + VNXBalance + StructuredProductsBalance || 0;
    let fundsBalance = 0;
    let dcrBalance = 0;
    let balancesFunds = { btcBalance: 0, ethBalance: 0, eurBalance: 0, usdcBalance: 0 };
    const fundsAccount = tradingAccountBase.Accounts?.filter(account => account.Type === AccountTypes.FUNDS)[0] || [];
    balancesFunds = {
      ...balancesFunds,
      ...fundsAccount?.Positions?.reduce(function (acc, fundsPosition, i) {
        acc[fundsPosition.Symbol.Ticker.toLowerCase() + 'Balance'] = fundsPosition.AvailQty;
        return acc;
      }, {}),
    };

    const symbolName = tradingAccountBase?.Symbol.Ticker;
    let accounts = [];
    for (let account of tradingAccounts) {
      const tradingAccount = account;
      const fundsAccount = tradingAccount.Accounts.filter(account => account.Type === AccountTypes.FUNDS)[0];

      const dcrAccount = tradingAccount.Accounts.filter(account => account.Type === AccountTypes.DCR)[0];
      dcrBalance = dcrAccount?.Balance || 0;
      fundsBalance = fundsAccount?.Balance + VNXBalance;

      accounts.push({
        tradingAccountId: tradingAccount.id,
        accNo: tradingAccount.AccNo,
        symbolName: tradingAccount?.Symbol.Ticker,
        balance: tradingAccount?.Balance || 0,
        FUNDS: {
          id: fundsAccount.id,
          balance: fundsAccount.Balance || 0,
          type: fundsAccount.Type,
          symbolName: fundsAccount.Symbol.Ticker,
          positions: mapPositions(fundsAccount.Positions),
        },
        DCR: {
          id: dcrAccount.id,
          balance: dcrAccount.Balance || 0,
          type: dcrAccount.Type,
          symbolName: dcrAccount.Symbol.Ticker,
          positions: mapPositions(dcrAccount.Positions),
        },
      });
    }

    return {
      VNXSymbolID,
      VNXType,
      symbolName,
      tradingBalance,
      dcrBalance,
      fundsBalance,
      ...balancesFunds,
      VNXBalance,
      VNXQuantity,
      VNXAccountID,
      VNXPositions,
      accounts,
    };
  }
  return {
    VNXType: 'VNX',
    symbolName: '',
    tradingBalance: 0,
    dcrBalance: 0,
    fundsBalance: 0,
    btcBalance: 0,
    ethBalance: 0,
    eurBalance: 0,
    usdcBalance: 0,
    VNXBalance: 0,
    VNXQuantity: 0,
    VNXSymbolID: '',
    VNXPositions: [
      {
        id: '',
        name: AssetSymbols.VNXLU,
        qty: 0,
        availableQty: 0,
        type: PositionTypes.VNX,
        accountId: '',
        balanceEUR: 0,
        blockedQty: 0,
        pendingQty: 0,
        isTransactions: false,
      },
    ],
    accounts: [
      {
        tradingAccountId: '',
        accNo: '',
        symbolName: '',
        balance: 0,
        FUNDS: {
          balance: 0,
          type: '',
          symbolName: '',
          positions: [
            {
              accountId: '',
              availableQty: 0,
              balanceEUR: 0,
              blockedQty: 0,
              id: '',
              name: AssetSymbols.EUR,
              pendingQty: 0,
              qty: 0,
              type: PositionTypes.CURRENCY,
              isTransactions: false,
            },
            {
              accountId: '',
              availableQty: 0,
              balanceEUR: 0,
              blockedQty: 0,
              id: '',
              name: AssetSymbols.BTC,
              pendingQty: 0,
              qty: 0,
              type: PositionTypes.CRYPTO,
              isTransactions: false,
            },
            {
              accountId: '',
              availableQty: 0,
              balanceEUR: 0,
              blockedQty: 0,
              id: '',
              name: AssetSymbols.ETH,
              pendingQty: 0,
              qty: 0,
              type: PositionTypes.CRYPTO,
              isTransactions: false,
            },
            {
              accountId: '',
              availableQty: 0,
              balanceEUR: 0,
              blockedQty: 0,
              id: '',
              name: AssetSymbols.USDC,
              pendingQty: 0,
              qty: 0,
              type: PositionTypes.ALT_COIN,
              isTransactions: false,
            },
          ],
        },
        DCR: {
          balance: 0,
          type: '',
          symbolName: '',
          positions: [],
        },
      },
    ],
  };
};

export const mapSymbolsMyAssets = items => mapAssets(items);

export const mapSymbols = items =>
  items.map(item => ({
    isSupported: item.IsSupported,
    parentId : item.ParentId ,
    ticker: item.Ticker,
    name: item.Name,
    type: item.Type,
    id: item.id,
  }));

export const wrapperSymbols = element =>
  symbolsModel[element.asset ?? element] || {
    symbol: element[0],
    color: '#AE8E41',
    fullName: element.asset || element,
  };
