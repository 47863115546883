import { withStyles } from 'tss-react/mui';
import React from 'react';
import { Button, Typography, Grid } from '@mui/material';
import { cx } from '@emotion/css';

import { UrlRecognize } from 'ui';
import { getDateDayMonthYearTimeFormat } from 'lib/date';

import { NotificationStatus } from '../models';
import { theme } from '../../../theme';

const NotificationDetails = withStyles(({ classes, item, onMarkRead }) => (
  <Grid container className={item.status === 'new' ? cx(classes.root, classes.new) : classes.root} direction="row" id={item.id}>
    <Grid container item className={classes.textContainer} direction="column" xs={true}>
      <Typography className={item.status === 'new' ? cx(classes.date, classes.newTxt) : classes.date} variant="body2">
        {getDateDayMonthYearTimeFormat(item.createdAt)}
      </Typography>
      <Typography className={cx(item.status === 'new' ? cx(classes.text, classes.newTxt) : classes.text, 'body3')} component="div" variant="body2">
        <UrlRecognize item={item.message} />
      </Typography>
    </Grid>
    {item.status === NotificationStatus.NEW && (
      <Grid item sm="auto" xs={12}>
        <Button className={classes.markBtn} onClick={() => onMarkRead(item)}>
          Mark as read
        </Button>
      </Grid>
    )}
  </Grid>
), {
  root: {
    width: '100%',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    borderRadius: '8px',
    backgroundColor: '#F4F5F6',
    flexWrap: 'nowrap',
    '@media screen and (max-device-width: 412px) ': {
      flexWrap: 'wrap',
    },
    '@media screen and (max-width: 440px) ': {
      flexWrap: 'wrap',
    },
  },
  textContainer: {
    paddingRight: theme.spacing(2),
    '@media screen and (max-device-width: 412px) ': {
      paddingBottom: theme.spacing(2),
    },
    '@media screen and (max-width: 440px) ': {
      paddingBottom: theme.spacing(2),
    },
  },
  date: {
    color: '#868C99',
    opacity: 0.5,
    marginBottom: theme.spacing(2),
  },
  text: {
    color: '#4A4F57',
    wordBreak: 'break-word',
  },
  new: {
    backgroundColor: '#CEF2FF',
  },
  newTxt: {
    color: '#005F81',
  },
  markBtn: {
    height: 24,
    border: '1px solid #70DAFF',
    background: 'transparent',
    color: '#00BDFF',
    fontSize: '12px',
    padding: '2px 12px',

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  button: {
    border: '1px solid #70DAFF',
  },
});

export { NotificationDetails };
