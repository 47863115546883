import { Navigate } from 'react-router-dom';
import React from 'react';

import { withdrawalAddressesRoutes } from 'features/withdrawal-addresses/routes';
import { walletRoutes } from 'features/wallet/routes';
import { transactionsRoutes } from 'features/transactions/routes';
import { termsRoutes } from 'features/terms';
import { structuredProductRoutes } from 'features/structured-product/routes';
import { settingsRoutes } from 'features/settings/routes';
import { offeringRoutes } from 'features/offering/';
import { notificationCenterRoutes } from 'features/notifications-center/routes';
import { kycRoutes } from 'features/kyc/routes';
import { accountRoutes } from 'features/account';
import { twoFactorAuthRoutes } from 'features/2fa/routes';

import { NotFoundPage } from './not-found-page';
import { CommonTemplate } from './components';

export const commonRoutes = () => [
  {
    path: '/',
    element: <CommonTemplate />,
    children: [
      ...accountRoutes,
      ...walletRoutes,
      ...transactionsRoutes,
      ...settingsRoutes,
      ...notificationCenterRoutes,
      ...withdrawalAddressesRoutes,
      ...twoFactorAuthRoutes,
      ...termsRoutes,
      ...kycRoutes,
      ...structuredProductRoutes,
      ...offeringRoutes,
    ],
  },
  {
    path: '/404',
    element: <NotFoundPage />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];
