import { withStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Link, Typography, Grid } from '@mui/material';

import { FAQ_URL, SUPPORT_URL } from '../constants';

const styles = theme => ({
  root: {
    width: '100%',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '80px',
  },
  helpLinks: {},
  helpLink: {
    paddingRight: '15px',
    paddingBottom: '10px',
    textDecoration: 'none',
    color: '#00BDFF',
    fontSize: '14px;',
  },
  rights: {
    fontSize: '14px',
    color: '#BABEC5',
  },
  grid: {
    padding: '25px 20px',
  },
});

const Iubenda = ({ classes, policyId }) => {
  useEffect(() => {
    var s = document.createElement('script');
    let tag = document.getElementsByTagName('script')[0];

    s.src = 'https://cdn.iubenda.com/iubenda.js';

    tag.parentNode.insertBefore(s, tag);
  }, []);

  return (
    <Link
      className={`iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe ${classes.helpLink}`}
      href={`https://www.iubenda.com/privacy-policy/${policyId}`}
      title="Privacy Policy "
      //class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe  "
    >
      Privacy
    </Link>
  );
};

const FooterView = ({ classes }) => {
  const navigate = useNavigate();
  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-start" className={classes.grid} justifyContent="space-between" wrap="nowrap">
        <Grid container className={classes.helpLinks} justifyContent="flex-start">
          <Link className={classes.helpLink} href={FAQ_URL} rel="noreferrer noopener" target="_blank">
            FAQ
          </Link>
          <Link
            className={classes.helpLink}
            onClick={() => {
              navigate('/terms/general');
            }}
          >
            General Terms
          </Link>
          <Link
            className={classes.helpLink}
            onClick={() => {
              navigate('/terms/frt');
            }}
          >
            FRT Terms
          </Link>
          <Iubenda classes={classes} policyId="28209609" />
        </Grid>
        <Grid container className={classes.rights} justifyContent="flex-end">
          <Link className={classes.helpLink} href={SUPPORT_URL} rel="noreferrer noopener" target="_blank">
            Support
          </Link>
          <Typography variant="inherit">© 2021 All rights reserved</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export const Footer = withStyles(FooterView, styles);
