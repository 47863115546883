import { withStyles } from 'tss-react/mui';
import { Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Suspense, useEffect, useMemo } from 'react';
import { Divider, Toolbar, CssBaseline, IconButton, Hidden, Grid, AppBar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { Spinner } from 'ui';
import { getKYCStatus } from 'features/kyc/status';
import { KYCResults } from 'features/kyc/models';
import { accountSelector, clientSelector, kycReportSelector } from 'features/account/selectors';
import { ReactComponent as LogoIcon } from 'assets/icons/Logo-white.svg';

import { actions as commonActions } from '../symbiotes';
import { getNavigationSelector } from '../selectors';
import { useWindowSize } from '../hooks';
import { WIDTH_PANEL } from '../constants';
import { AccountLoader } from '../../auth';

import { SideNav } from './sidenav';
import { KYCBanner } from './kyc-banner';
import { Footer } from './footer';

const styles = theme => ({
  root: {
    width: '100%',
    background: '#fff',
    display: 'flex',
    flexDirection: 'row',
    flex: '1',
    minHeight: '100vh',
    [theme.breakpoints.down('md')]: {
      marginTop: '50px',
    },
  },
  container: {
    width: '100%',
    flex: '1',
    alignContent: 'flex-start',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: 70,
    paddingRight: 70,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    '& > div': {
      minHeight: '100%',
    },
  },
  bigContainer: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    alignContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${WIDTH_PANEL}px)`,
      marginLeft: WIDTH_PANEL,
    },
  },
  divider: {
    height: 1,
    width: '100%',
  },
  menuButton: {
    '&:hover': {
      background: 'rgba(0,0,0,0)',
    },
  },
  appBar: {
    background: '#fff',
  },
  logo: {
    display: 'block',
  },
  logoContainer: {
    width: 'calc(100% - 74px)',
    justifyContent: 'center',
  },
});

const mapStateToProps = state => ({
  client: clientSelector(state),
  account: accountSelector(state),
  KYCReport: kycReportSelector(state),
  navigationPosition: getNavigationSelector(state),
});

const mapDispatchToProps = dispatch => ({
  setWindowSize: size => dispatch(commonActions.setWindowSize(size)),
  setKYCBannerSize: size => dispatch(commonActions.setKYCBannerSize(size)),
});

const CommonTemplateView = ({ classes, client, account, KYCReport, navigationPosition, setWindowSize, setKYCBannerSize }) => {
  const kycBannerRef = React.createRef();
  const KYCStatus = getKYCStatus(KYCReport);
  const [ mobileOpen, setMobileOpen ] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const sizeWindow = useWindowSize();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {}, [ KYCStatus ]);

  useEffect(() => {
    setWindowSize(sizeWindow);
    setKYCBannerSize({
      width: kycBannerRef.current?.clientWidth,
      height: kycBannerRef.current?.clientHeight,
    });
  }, [ kycBannerRef, sizeWindow, setWindowSize, setKYCBannerSize ]);

  const shift = useMemo(() => (KYCStatus === KYCResults.NEW ? 152 : 82), [ KYCStatus ]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AccountLoader>
        <SideNav handleDrawerToggle={handleDrawerToggle} key="sidenav" mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        <Hidden mdUp implementation="css">
          <AppBar className={classes.appBar} position="fixed">
            <Toolbar>
              <IconButton aria-label="menu" className={classes.menuButton} size="large" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
              <Grid container className={classes.logoContainer}>
                <LogoIcon className={classes.logo} height={14} />
              </Grid>
            </Toolbar>
          </AppBar>
        </Hidden>
        <Grid container className={classes.bigContainer}>
          {client &&
            account &&
            (navigationPosition !== null ? (
              <KYCBanner KYCReport={KYCReport} KYCStatus={KYCStatus} navigationPosition={navigationPosition} ref={kycBannerRef} />
            ) : null)}

          <Grid container className={classes.container} style={{ flexGrow: 1 }}>
            <Suspense fallback={<Spinner />}>
              <Outlet />
            </Suspense>
          </Grid>
          <Divider className={classes.divider} variant="fullWidth" />
          <Footer />
        </Grid>
      </AccountLoader>
    </div>
  );
};

export const CommonTemplate = connect(mapStateToProps, mapDispatchToProps)(withStyles(CommonTemplateView, styles));
