import { withStyles } from 'tss-react/mui';
import React from 'react';
import { Grid, InputLabel, FormHelperText, FormControl, InputBase, Paper, Typography, Button } from '@mui/material';

const styles = theme => ({
  main: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#F4F4F4',
  },
  paperSignIn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(7),
    boxShadow: 'inherit',
    borderRadius: '8px',
    backgroundColor: '#25272B',
    color: '#FFF',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(7),
    boxShadow: 'inherit',
    borderRadius: '8px',
    backgroundColor: '#25272B',
    color: '#FFF',
  },
  titlCode: {
    fontWeight: 600,
    fontSize: '26px',
    fontHeight: '32px',
    marginBottom: '74px',
  },
  form: {
    width: '100%',
  },
  titleSignIn: {
    color: '#fff',
    paddingBottom: theme.spacing(8),
  },
  formControlSignIn: {
    paddingBottom: theme.spacing(7),
  },
  formButton: {
    marginBottom: theme.spacing(8),
  },
  inputRoot: {
    'label + &': {
      marginTop: theme.spacing(4),
    },
    '& input': {
      '&:-webkit-autofill': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:focus': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
      '&:-webkit-autofill:hover': {
        transition: 'background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s',
      },
    },
  },
  inputField: {
    fontSize: 14,
    padding: '15px 20px',
    width: '100%',
    borderRadius: '8px',
    background: '#393C43',
    color: '#fff',
    transition: theme.transitions.create([ 'border-color', 'box-shadow' ]),
    '&::placeholder': {
      textOverflow: 'ellipsis',
      color: '#9FA4AE',
    },
    '&:focus': {
      borderColor: '#9FA4AE',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      background: '#393C43',
    },
  },
  inputLabel: {
    color: '#fff',
    marginBottom: theme.spacing(2),
    '&.Mui-focused': {
      color: '#FFF',
    },
    position: 'static',
  },
  subTitle: {
    color: '#9FA4AE',
  },
  marginRight: {
    marginRight: theme.spacing(3),
  },
});

const InputCodeTwoFactorView = ({ classes, handleContinue, setCode, code, error, isError }) => (
  <Paper className={classes.paperSignIn}>
    <Grid container alignItems="baseline" className={classes.titleSignIn} justifyContent="space-between">
      <Typography className={classes.marginRight} variant="h4">
        Two-Factor Authentication
      </Typography>{' '}
    </Grid>

    <Grid container justifyContent="center">
      <form className={classes.form} onSubmit={handleContinue}>
        <Grid container className={classes.formControlSignIn}>
          <FormControl fullWidth error={isError} margin="normal">
            <InputLabel className={classes.inputLabel} htmlFor="code">
              <Typography variant="body2">Enter the verification code generated by Google Authenticator app on your phone.</Typography>
            </InputLabel>
            <InputBase
              autoFocus
              required
              classes={{
                root: classes.inputRoot,
                input: classes.inputField,
              }}
              id="code"
              name="code"
              placeholder="Verification code"
              type="text"
              value={code}
              onChange={(e) => {
                //clearError()
                setCode(e.target.value);
              }}
            />
            <FormHelperText error={isError}>{isError && String(error)}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid container alignItems="flex-start" justifyContent="center">
          <Button fullWidth className="normal" size="large" type="submit">
            VERIFY
          </Button>
        </Grid>
      </form>
    </Grid>
  </Paper>
);
export const InputCodeTwoFactor = withStyles(InputCodeTwoFactorView, styles);
