import { withStyles } from 'tss-react/mui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';

import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg';

const styles = theme => ({
  root: {
    marginLeft: theme.spacing(2),
  },
  copy: {
    height: 20,
    width: 20,
    cursor: 'pointer',
    '& path': {
      fill: '#9FA4AE',
    },
  },
});

const CopyClipboardView = ({ text, classes }) => {
  const [ isCopy, setIsCopy ] = useState(false);
  useEffect(() => {
    if (isCopy) {
      setTimeout(() => {
        setIsCopy(false);
      }, 600);
    }
  }, [ isCopy ]);
  return (
    <Grid container className={classes.root} wrap="nowrap">
      <CopyToClipboard
        text={`${text}`}
        onCopy={() => {
          setIsCopy(true);
        }}
      >
        <Tooltip arrow classes={{ arrow: 'arrow', tooltip: 'tooltip' }} placement="top-end" title={isCopy ? 'Copied' : 'Click to copy'}>
          <CopyIcon className={classes.copy} />
        </Tooltip>
      </CopyToClipboard>
    </Grid>
  );
};

export const CopyClipboard = withStyles(CopyClipboardView, styles);
