import { handleFetching } from 'symbiote-fetching';

import { actions } from '../common/symbiotes';
import { getResponse } from '../common/response';

import { termsApi } from './api';

export const saveTerms = query =>
  handleFetching(actions.fetchOne, {
    noThrow: false,
    async run(dispatch) {
      try {
        const response = await dispatch(termsApi.saveTerms, query);
        return getResponse(response);
      } catch (error) {
        return { ok: false, error: { data: String(error) } };
      }
    },
  });
