import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

import { AssetSymbols, PositionTypes } from 'features/common/models';

export const initialState = {
  fetchOne: initialFetching,
  fetchClient: initialFetching,
  fetchAccount: initialFetching,
  fetchUpdateAccount: initialFetching,
  fetchClientPnL: initialFetching,
  fetchClientSymbolsMyAssets: initialFetching,
  client: null,
  account: {
    symbolName: '',
    tradingBalance: 0,
    dcrBalance: 0,
    btcBalance: 0,
    ethBalance: 0,
    eurBalance: 0,
    usdcBalance: 0,
    VNXBalance: 0,
    VNXQuantity: 0,
    VNXPositions: [
      {
        id: '',
        name: AssetSymbols.VNXLU,
        qty: 0,
        availableQty: 0,
        type: PositionTypes.VNX,
        accountId: '',
        balanceEUR: 0,
        blockedQty: 0,
        pendingQty: 0,
      },
    ],
    accounts: [
      {
        accNo: '',
        symbolName: '',
        balance: 0,
        FUNDS: {
          balance: 0,
          type: '',
          symbolName: '',
          positions: [],
        },
        DCR: {
          balance: 0,
          type: '',
          symbolName: '',
          positions: [],
        },
      },
    ],
    position: {
      availVNX: 0,
    },
    positionCurrent: {},
  },
  currentAccount: {
    accNo: '',
    symbolName: '',
    balance: 0,
    FUNDS: {
      balance: 0,
      type: '',
      symbolName: '',
      positions: {
        EUR: {
          accountId: '',
          availableQty: 0,
          balanceEUR: 0,
          blockedQty: 0,
          id: '',
          name: AssetSymbols.EUR,
          pendingQty: 0,
          qty: 0,
          type: PositionTypes.CURRENCY,
        },
        BTC: {
          accountId: '',
          availableQty: 0,
          balanceEUR: 0,
          blockedQty: 0,
          id: '',
          name: AssetSymbols.BTC,
          pendingQty: 0,
          qty: 0,
          type: PositionTypes.CRYPTO,
        },
        ETH: {
          accountId: '',
          availableQty: 0,
          balanceEUR: 0,
          blockedQty: 0,
          id: '',
          name: AssetSymbols.ETH,
          pendingQty: 0,
          qty: 0,
          type: PositionTypes.CRYPTO,
        },
        USDC: {
          accountId: '',
          availableQty: 0,
          balanceEUR: 0,
          blockedQty: 0,
          id: '',
          name: AssetSymbols.USDC,
          pendingQty: 0,
          qty: 0,
          type: PositionTypes.ALT_COIN,
          isTransactions: false,
        },
      },
    },
    DCR: {
      balance: 0,
      type: '',
      symbolName: '',
      positions: {},
    },
  },
  symbols: [],
  position: { availFunds: 0, availVNX: 0 },
  positionCurrent: { availFunds: 0, availVNX: 0 },
  clientPnL: { PnL: 0 },
  symbolsMyAssets: [],
};
const { ALT_COIN, CRYPTO, CURRENCY, DCR, DGR, FRT, VNX, STRUCTURED_DEPOSIT } = PositionTypes;
const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchClient: createFetching('fetchClient'),
  fetchAccount: createFetching('fetchAccount'),
  fetchUpdateAccount: createFetching('fetchUpdateAccount'),
  fetchClientPnL: createFetching('fetchClientPnL'),
  fetchClientSymbolsMyAssets: createFetching('fetchClientSymbolsMyAssets'),
  setCurrentAccount: (state, data) => {
    let newData = data;
    let funds = state.currentAccount.FUNDS.positions;
    let dcr = state.currentAccount.DCR.positions;
    data.FUNDS.positions.forEach((value, index) => (funds[value.name] = value));
    newData.FUNDS.positions = funds;
    data.DCR.positions.forEach((value, index) => (dcr[value.name] = value));
    newData.DCR.positions = dcr;
    return { ...state, currentAccount: newData };
  },
  setClient: (state, data) => ({ ...state, client: data }),
  setAccount: (state, data) => ({ ...state, account: data }),
  setPosition: (state, data) => ({ ...state, position: data }),
  setPositionCurrent: (state, data) => ({ ...state, positionCurrent: data }),
  setDefaultPositions: (state, data) => {
    let newData = data;
    let currentAccount = { ...state.currentAccount };
    let account = { ...state.account };
    currentAccount.FUNDS.positions.EUR.id = newData.filter(value => value.ticker === AssetSymbols.EUR)[0].id;
    currentAccount.FUNDS.positions.BTC.id = newData.filter(value => value.ticker === AssetSymbols.BTC)[0].id;
    currentAccount.FUNDS.positions.ETH.id = newData.filter(value => value.ticker === AssetSymbols.ETH)[0].id;
    currentAccount.FUNDS.positions.USDC.id = newData.filter(value => value.ticker === AssetSymbols.USDC)[0].id;

    account.VNXPositions[0].id = newData.filter(value => value.ticker === AssetSymbols.VNXLU)[0].id;

    return { ...state, currentAccount: { ...currentAccount }, account: { ...account } };
  },
  setSymbols: (state, data) => {
    const symbols = data.filter(
      value => [ ALT_COIN, CRYPTO, CURRENCY, DCR, DGR, FRT, VNX, STRUCTURED_DEPOSIT ].includes(value.type) &&
        value.parentId == null &&
        value.isSupported === true
    );

    return {
      ...state,
      symbols: symbols,
    };
  },
  setClientPnL: (state, data) => ({ ...state, clientPnL: data }),
  setSymbolsMyAssets: (state, data) => ({ ...state, symbolsMyAssets: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'account');
