import { withStyles } from 'tss-react/mui';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Link, Grid, FormControlLabel } from '@mui/material';

import { termsDocType, termsPath } from 'features/terms/models';
import { useWindowSize } from 'features/common/hooks';
import { ReactComponent as Full } from 'assets/full.svg';
import { ReactComponent as ExitFull } from 'assets/exitFull.svg';

import { Checkbox } from './checkbox';

const icons = {
  Full: <Full />,
  Exit: <ExitFull />,
};

const styles = theme => ({
  form: {},
  title: {
    fontWeight: 600,
    fontSize: '26px',
    fontHeight: '32px',
  },
  confirm: {
    color: '#FFF',
    marginTop: '20px',
    '& span span> a': {
      textDecoration: 'none',
      color: '#00BDFF',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
        color: '#00A2DB',
      },
      '&:active': {
        textDecoration: 'underline',
        color: '#008ABB',
      },
    },
  },
  footer: {
    marginTop: '30px',
    paddingLeft: '70px',
    paddingRight: '70px',
  },
  wrapperTerms: {
    display: 'flex',
    border: '1px solid #E3E3E3',
    flexDirection: 'column',
    height: '40vh',
    backgroundColor: '#25272B',
    maxWidth: '1160px',
    overflowY: 'scroll',
    alignItems: 'center',
    '@media screen and (max-device-width: 920px)': {
      width: 'calc(80vw + 38px)',
    },
  },
  helpLink: {
    paddingRight: '15px',
    paddingBottom: '10px',
    textDecoration: 'none',
    color: '#BABEC5',
    fontSize: '14px;',
  },
  divider: {
    margin: '5px 0',
  },
  icon: {
    position: 'absolute',
    padding: '5px',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
  },
  fullScreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: '999',
    height: '100%',
    maxWidth: 'none',
  },
});

const Iubenda = ({ classes, policyId }) => {
  useEffect(() => {
    var s = document.createElement('script');
    let tag = document.getElementsByTagName('script')[0];

    s.src = 'https://cdn.iubenda.com/iubenda.js';

    tag.parentNode.insertBefore(s, tag);
  }, []);

  return (
    <Link
      className={`iubenda-nostyle no-brand iubenda-noiframe iubenda-embed ${classes.helpLink}`}
      href={`https://www.iubenda.com/privacy-policy/${policyId}`}
      title="Privacy Policy "
      //class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe  "
    >
      Privacy Terms
    </Link>
  );
};

const TermsFormView = ({ classes, handleSubmit }) => {
  const { GENERAL } = termsDocType;
  const [ confirmTerms, setConfirmTerms ] = useState(false);
  const [ confirmPrivacy, setConfirmPrivacy ] = useState(false);
  const [ confirmPersonalData, setConfirmPersonalData ] = useState(false);
  const [ numPages, setNumPages ] = useState(null);
  const [ fullScreen, setFullScreen ] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const pagesCount = useMemo(() => {
    let pages = [];
    for (let i = 0; i < numPages; i++) {
      pages[i] = i + 1;
    }
    return pages;
  }, [ numPages ]);

  const currentWindow = useWindowSize(300);

  const availWidth = useMemo(() => currentWindow?.width * 0.9, [ currentWindow ]);

  const fullScreenProps = useMemo(() => {
    if (!fullScreen)
      return {
        wrapper: classes.wrapperTerms,
        icon: icons.Full,
      };
    if (fullScreen)
      return {
        wrapper: `${classes.wrapperTerms} ${classes.fullScreen}`,
        icon: icons.Exit,
      };
  }, [ fullScreen, classes ]);

  const file =  useMemo(
    () => ({ url: termsPath[GENERAL] }),
    [ GENERAL ]
  );

  return (
    <Fragment>
      <div className={fullScreenProps.wrapper}>
        <div className={classes.icon} onClick={() => setFullScreen(prev => !prev)}>
          {fullScreenProps.icon}
        </div>
        <Document file={file} renderMode="canvas" onLoadSuccess={onDocumentLoadSuccess}>
          {pagesCount.map(el => (
            <>
              <Page key={`page_${el}`} pageNumber={el} renderAnnotationLayer={false} width={!fullScreen ? 1060 : availWidth} />
              <Divider className={classes.divider} variant="fullWidth" />
            </>
          ))}
        </Document>
      </div>
      <Grid container direction="column">
        <FormControlLabel
          className={classes.confirm}
          control={
            <Checkbox
              checked={confirmTerms}
              onChange={(e) => {
                setConfirmTerms(e.target.checked);
              }}
            />
          }
          label=" I do hereby confirm that I have read, understood and accepted the Terms and Conditions."
        />
        <FormControlLabel
          className={classes.confirm}
          control={
            <Checkbox
              checked={confirmPrivacy}
              onChange={(e) => {
                setConfirmPrivacy(e.target.checked);
              }}
            />
          }
          label={
            <span>
              I have read and agree with the <Iubenda classes={classes} policyId="28209609" />
            </span>
          }
        />
        <FormControlLabel
          className={classes.confirm}
          control={
            <Checkbox
              checked={confirmPersonalData}
              onChange={(e) => {
                setConfirmPersonalData(e.target.checked);
              }}
            />
          }
          label="I do freely give my express, informed, specific and unambiguous consent to VNX Commodities A.G. including without limitation its affiliates and outsource services providers for collecting, processing, profiling, pseudonymisation and keeping my personal data."
        />
      </Grid>
      <Grid container alignItems="center" className={classes.footer} direction="column" justifyContent="center">
        <Button className="normal" disabled={!(confirmTerms && confirmPrivacy && confirmPersonalData)} size="large" type="submit" onClick={handleSubmit}>
          Create account
        </Button>
      </Grid>
    </Fragment>
  );
};

TermsFormView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const TermsForm = withStyles(TermsFormView, styles);
