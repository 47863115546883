import { useLocation, useRoutes } from 'react-router-dom';
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import React, { Fragment } from 'react';

import { useScript } from 'features/common/hooks';

import { routes } from './routes';
import { Intercom } from './features/intercom';
import { useGoogleAnalyticsHistoryListen } from './features/google-analytics/effects';

import './global-styles.css';
import './react-vis.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const App = () => {
  const location = useLocation();
  useGoogleAnalyticsHistoryListen(location);
  useScript('//js-eu1.hs-scripts.com/25564816.js', 'hs-script-loader', true, true);

  const rootRoutes = useRoutes(routes);

  return (
    <Fragment>
      {rootRoutes}
      <Intercom />
    </Fragment>
  );
};

export default App;
