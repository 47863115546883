import { isEmpty } from 'lib/object';
import { getDateFormat } from 'lib/date';
import { AssetSymbols, PositionTypes } from 'features/common/models';

export const mapWallet = (item) => {
  if (item) {
    return {
      id: item.id,
      address: item.Address,
      blockchainId: item.BlockchainId,
      contract: item.Contract,
      minDeposit: item.MinDeposit,
      errMesage: item.errMessage || null,
    };
  }
  return {
    id: null,
    address: null,
  };
};

export const mapCommission = item => ({
  fee: item.fee,
  crypto: item.crypto,
});

export const mapDefaultPositions = (items) => {
  let positions = [];
  items.forEach((item) => {
    if ([ AssetSymbols.EUR, AssetSymbols.ETH, AssetSymbols.BTC, AssetSymbols.USDC, AssetSymbols.VNXLU ].includes(item.ticker)) {
      positions.push(item);
    }
  });
  return positions;
};

export const mapBankDetail = (items) => {
  let item = items.pop() || {};
  if (isEmpty(item)) {
    return {};
  } else {
    return {
      id: item.id,
      clientId: item?.ClientId,
      name: item?.BankDetail?.Name || '',
      accountNumber: item?.BankDetail?.AccountNumber,
      bic: item?.BankDetail?.Bic || '',
      swift: item?.BankDetail?.Swift || '',
      bank: item?.BankDetail?.Bank || '',
      iban: item?.BankDetail?.Iban || '',
      bankAddress: item?.BankDetail?.BankAddress || '',
      beneficiary: item?.BankDetail?.Beneficiary || '',
      beneficiaryAddress: item?.BankDetail?.BeneficiaryAddress || '',
      memo: item?.BankDetail?.Memo,
      symbolId: item?.BankDetail?.Symbol?.id || null,
      ticker: item?.BankDetail?.Symbol?.Ticker || '',
    };
  }
};

export const mapBankDetails = (items) => {
  if (items) {
    return items.map(item => ({
      id: item.id,
      clientId: item?.ClientId,
      name: item?.BankDetail?.Name || '',
      accountNumber: item?.BankDetail?.AccountNumber,
      bic: item?.BankDetail?.Bic || '',
      swift: item?.BankDetail?.Swift || '',
      bank: item?.BankDetail?.Bank || '',
      iban: item?.BankDetail?.Iban || '',
      bankAddress: item?.BankDetail?.BankAddress || '',
      beneficiary: item?.BankDetail?.Beneficiary || '',
      beneficiaryAddress: item?.BankDetail?.BeneficiaryAddress || '',
      memo: item?.BankDetail?.Memo,
      symbolId: item?.BankDetail?.Symbol?.id || null,
      ticker: item?.BankDetail?.Symbol?.Ticker || '',
    }));
  } else {
    return [];
  }
};

export const mapCryptoWithdrawalPosition = item => ({
  check: item.check,
  avail: item.avail,
  fee: item.totalFee,
  error: item.error,
  balance: item.balance,
});

export const mapCurrencyWithdrawalPosition = item => ({
  check: item.check,
  fee: item.withdrawalFee,
  balance: item.balance,
});

export const mapCurrencyAcquiringPositions = item => ({
  check: item.check,
  fee: item.fee,
  percentFee: item.percentFee,
  availBalance: item.avail,
  youGet: item.youGet,
  minimalFee: item.minimalFee,
  balance: item.balance,
});

export const mapAcquiringCheckouts = item => ({
  checkoutId: item.chekouts?.id,
});

export const mapAcquiringCheckoutsPayment = item => ({
  status: item.status,
  message: item.message,
  amount: item.resp?.amount,
  currency: item.resp?.currency,
});

export const mapCountries = (items) => {
  if (items?.rows) {
    return items.rows.map(item => ({
      id: item.id,
      country: item.Country,
      alpha2Code: item.Alpha2Code,
    }));
  } else {
    return [];
  }
};

const mapProductPosition = (item) => {
  let position = {};
  if (item.ProductType === PositionTypes.STRUCTURED_DEPOSIT) {
    position = {
      id: item.OfferAssetId,
      underlyingId: item.UnderlyingAssetId,
      amount: item.Amount,
      redemptionAmount: item.RedemptionAmount,
      maturityDate: getDateFormat(item.MaturityDate),
      type: item.OfferAssetType,
      underlyingType: item.UnderlyingAssetType,
      underlyingAssetTicker: item.UnderlyingAssetTicker,
      offerAssetTicker: item.OfferAssetTicker,
      successFee: item.SuccessFee,
      successFeeVnxlu: item.SuccessFeeVnxlu,
      maturityDateTimestamp: Date.parse(item.MaturityDate),
      logo: item.LogoSp,
      symbolId: item.OfferAssetId,
    };
  } else {
    position = {
      id: item.Symbol.id,
      name: item.Symbol.Ticker,
      blockchainId: item.Symbol.BlockchainId,
      nameSymbol: item.Symbol.Name,
      qty: item.Quantity || 0,
      availableQty: item.AvailQty || 0,
      type: item.Symbol.Type,
      accountId: item.AccountId,
      blockedQty: item.BlockedQty,
      pendingQty: item.PendingQty,
      plannedBuyQty: item.PlannedBuyQty,
      plannedSellQty: item.PlannedSellQty,
      isTransactions: item.isTransactions === true,
      symbolId: item.Symbol?.id,
      deployContracts: item.deployContractBcs,
      availableToBuy: item.availableToBuy,
      availableToSell: item.availableToSell,
    };
  }

  if ([ PositionTypes.DGR, PositionTypes.FRT ].includes(item.ProductType)) {
    position = {
      ...position,
      groupName: item.Symbol?.Parent?.Ticker,
      idOfferingDS: item.Product?.id,
      status: item.Product?.Status,
    };
  }

  if (item.ui) {
    position = {
      ...position,
      ui: {
        logo: item.ui.icon,
        logoLarge: item.ui.large_logo,
        walletColor: item.ui.wallet_color,
        pieColor: item.ui.pie_color,
      },
    };
  }

  return position;
};

export const mapProductPositions = (items) => {
  let positions = {};
  items.forEach((item) => {
    if (positions[item.ProductType]) {
      positions[item.ProductType].push(mapProductPosition(item));
    } else {
      positions[item.ProductType] = [ mapProductPosition(item) ];
    }
  });

  return positions;
};
