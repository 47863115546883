import { withStyles } from 'tss-react/mui';
import React from 'react';
import { Button, Grid, SnackbarContent, Typography } from '@mui/material';
import { cx } from '@emotion/css';

import { theme } from 'theme';
import { WIDTH_PANEL } from 'features/common/constants';

import { getDateDayMonthYearTimeFormat, getFormatPastTime, getPastTime } from '../lib/date';

import { UrlRecognize } from './url-recognize';

export const Alert = withStyles(({ classes, date, title, message, full = true, status, onClickLink }) => (
  <Grid item className={full ? '' : status === 'new' ? cx(classes.root, classes.new) : classes.root}>
    {title && (
      <Typography className={classes.title} key={2} variant="subtitle1">
        {title}
      </Typography>
    )}
    <Typography className={cx(classes.date, 'body3')} key={1} variant="body2">
      {Boolean(full) ? getDateDayMonthYearTimeFormat(date) : getFormatPastTime(getPastTime(date))}
    </Typography>
    {message && full && (
      <Typography className={cx(classes.text, 'body3')} key={3} variant="body2">
        <UrlRecognize isPush={true} item={message} onClickLink={onClickLink} />
      </Typography>
    )}
  </Grid>
), {
  root: {
    height: '50px',
    position: 'relative',
  },
  new: {
    borderLeft: '7px solid #00BDFF',
  },
  date: {
    opacity: '0.5',
  },
  title: {
    fontWeight: 600,
  },
  text: {
    color: '#fff',
  },
});

export const Snackbar = withStyles((props) => {
  const { classes, notification, notistackRef, full, onClose, staticContext, KYCStatus, ...other } = props;

  const handleClose = id => () => {
    notistackRef.current.closeSnackbar(id);
    onClose(id);
  };

  const handleClickLink = async () => {
    await onClose(notification.id);
  };

  return (
    <SnackbarContent
      action={[
        <Button className={cx('white radiusLarge', classes.button)} key={notification.id} size="small" onClick={handleClose(notification.id)}>
          Ok, got it
        </Button>,
      ]}
      aria-describedby="client-snackbar"
      classes={{ action: classes.buttonAction, root: classes.root }}
      key={notification.id}
      message={<Alert {...notification} date={notification && notification.createdAt} full={full} onClickLink={handleClickLink} />}
      {...other}
    />
  );
}, {
  root: {
    padding: '16px 70px',
    background: '#00BDFF',
    borderRadius: 0,
    maxWidth: `calc(100vw - ${WIDTH_PANEL}px)`,
    width: `calc(100vw - ${WIDTH_PANEL}px)`,
    alignItems: 'flex-start',
    boxShadow: 'unset',
    '@media screen and (max-device-width: 412px) ': {
      padding: '16px 16px',
      maxWidth: `calc(100vw - ${0}px)`,
      width: `calc(100vw - ${0}px)`,
    },
    '@media screen and (max-width: 440px) ': {
      padding: '16px 16px',
      maxWidth: `calc(100vw - ${0}px)`,
      width: `calc(100vw - ${0}px)`,
    },
    '@media screen and (max-width: 889px) ': {
      padding: '16px 16px',
      maxWidth: `calc(100vw - ${0}px)`,
      width: `calc(100vw - ${0}px)`,
    },
  },
  buttonAction: {
    '@media screen and (max-device-width: 412px) ': {
      marginRight: theme.spacing(1),
    },
    '@media screen and (max-width: 440px) ': {
      marginRight: theme.spacing(1),
    },
    '@media screen and (max-width: 889px) ': {
      marginRight: theme.spacing(1),
    },
  },
});
