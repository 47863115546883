import { createFetching, initialFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

const initialState = {
  fetchOne: initialFetching,
  fetchWallets: initialFetching,
  fetchWalletTypes: initialFetching,
  fetchWallet: initialFetching,
  fetchCreateWallet: initialFetching,
  fetchUpdateWallet: initialFetching,
  fetchDeleteWallet: initialFetching,
  fetchWithdrawalAddress: initialFetching,
  fetchtWithdrawalAddressSymbols: initialFetching,
  fetchtMarketingPrograms: initialFetching,
  settings: [],
  wallets: [],
  bankDetailWallets: [],
  walletTypes: [],
  withdrawalAddresses: [],
  withdrawalAddressSymbols: [],
  marketingPrograms: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchClient: createFetching('fetchClient'),
  fetchWallets: createFetching('fetchWallets'),
  fetchWalletTypes: createFetching('fetchWalletTypes'),
  fetchWallet: createFetching('fetchWallet'),
  fetchCreateWallet: createFetching('fetchCreateWallet'),
  fetchUpdateWallet: createFetching('fetchUpdateWallet'),
  fetchDeleteWallet: createFetching('fetchDeleteWallet'),
  fetchWithdrawalAddress: createFetching('fetchWithdrawalAddress'),
  fetchtWithdrawalAddressSymbols: createFetching('fetchtWithdrawalAddressSymbols'),
  fetchtMarketingPrograms: createFetching('fetchtMarketingPrograms'),
  setSettings: (state, settings) => ({ ...state, settings }),
  setCurrentAccount: (state, data) => ({ ...state, currentAccount: data }),
  setWallets: (state, data) => ({ ...state, wallets: data }),
  setBankDetailWallets: (state, data) => ({ ...state, bankDetailWallets: data }),
  setWithdrawalAddress: (state, data) => ({ ...state, withdrawalAddresses: data }),
  setWalletTypes: (state, data) => ({ ...state, walletTypes: data }),
  setWallet: (state, data) => ({ ...state, wallets: data }),
  setWithdrawalAddressSymbols: (state, data) => ({ ...state, withdrawalAddressSymbols: data }),
  setMarketingPrograms: (state, data) => ({ ...state, marketingPrograms: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'settings');
