import React from 'react';

import { termsDocType } from './models';

const TermsPage = React.lazy(() => import('./pages/terms-page'));

const { GENERAL, FRT } = termsDocType;

export const termsRoutes = [
  {
    path: '/terms/general',
    element: <TermsPage type={GENERAL} />,
  },
  {
    path: '/terms/frt',
    element: <TermsPage type={FRT} />,
  },
];
